<template>
  <div class="w900">
    <h2 class="subBanner"><img src="@/assets/img/icon_gdia.svg" />{{$t('front.mypage.message')}}</h2>
    <div class="mybetwrap">
      <board-filter  @onSearch="onSearch"/>
      <!--div class="boardName">
        <div class="pagenamew">
          <span class="coutxt">{{$t('front.message.maxMsg')}} <span class="blc">14</span>{{$t('front.message.max')}} <span class="blc">20</span>{{$t('front.message.numMsg')}}</span>
        </div>
      </div-->
      <div class="board">
        <ul class="boardw first">
          <li class="w95">{{$t('front.board.checks')}}</li>
          <li class="w95">{{$t('front.board.check')}}</li>
          <li class="w600">{{$t('front.board.title')}}</li>
          <li class="w150">{{$t('front.board.sendTime')}}</li>
          <li class="w95">{{$t('front.board.delete')}}</li>
        </ul>

        <template v-if="list && list.length > 0">
          <template v-for="item in list" v-bind:key="item.msgIdx">
            <ul class="boardw">
              <li class="w95"><input type="checkbox" v-model="item.checked"></li>
              <li class="w95">
                <span class="ocmsg" :class="{'closemsg': item.msgStatus == 'N', 'openmsg': item.msgStatus != 'N'}"></span>
              </li>
              <li class="w600 textleft">
                <a @click="onMessageClick(item)">
                  <span class="nicon mr10">{{$t('front.boardCategory.notice')}}</span>
                  {{item.msgTitle}}
                  <span class="newicon rdbg ml10">N</span>
                </a>
              </li>
              <li class="w150">{{dateFormat(item.sendDate)}}</li>
              <li class="w95"><span class="nbicon rdbg" @click="onRemoveOne(item)">{{$t('front.board.delete')}}</span></li>
            </ul>
          </template>
        </template>
      </div>
      <div class="boardbtn">
        <div>
          <a class="grsbtn" @click="onAllCheck">{{ $t('front.board.AllSelect') }}</a>
          <a class="rdsbtn02" @click="onRemoveMsg">{{ $t('front.board.AllDelete') }}</a>
        </div>
        <a class="grbbtn" @click="onAllRead">{{ $t('front.board.AllRead') }}</a>
      </div>

      <pagination
          :pageNum="pageInfo.page"
          :pageSize="pageInfo.count_per_list"
          :totalCount="pageInfo.tatal_list_count"
          @goToPage="loadList"
      />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/ui/Pagination'
import BoardFilter from '@/components/common/BoardFilter'
import { getMsgList, setMsgRead, setMsgUpdate } from '@/api/board'
export default {
  name: 'messageList',
  components: { BoardFilter, Pagination },
  data () {
    return {
      list: [],
      searchParam: {
        searchOption: '',
        searchValue: ''
      },
      idxList: []
    }
  },
  created () {
    this.loadList()
  },
  methods: {
    onSearch (value) {
      this.searchParam.searchOption = value.type
      this.searchParam.searchValue = value.text
      this.loadList()
    },
    loadList (page) {
      const params = {
        page: page || this.pageInfo.page
      }
      const searchValue = this.searchParam.searchValue
      if (searchValue) {
        params.searchOption = this.searchParam.searchOption
        params.searchValue = this.searchParam.searchValue
      }
      getMsgList(params).then(response => {
        const result = response.data

        if (result.resultCode === '0') {
          const list = result.data.list
          if (result.data.pageInfo) {
            this.pageInfo = result.data.pageInfo
          }

          for (let i = 0, iLen = list.length; i < iLen; i++) {
            const item = list[i]
            item.checked = false
          }
          this.list = list
        }
      })
    },
    onMessageClick (item, isMobile) {
      item.searchParam = JSON.stringify(this.searchParam)
      if (isMobile) {
        location.href = '/msgRead/' + item.msgIdx
      } else {
        // this.goPageByName('messageRead', item)
        location.href = '/mypage/message/read/' + item.msgIdx
      }
    },
    onAllCheck (list) {
      this.allChecked = !this.allChecked
      this.list.forEach(item => {
        item.checked = this.allChecked
      })
    },
    async onRemoveOne (item) {
      const confirm = await this.onConfirm('front.message.confirmDeleteMessageMultiple')
      if (confirm) {
        const params = {
          msgIdx: item.msgIdx,
          recieveId: item.recieveId,
          status: 'N'
        }
        await setMsgUpdate(params).then(response => {
          this.onCheck('front.message.completeDeleteMessage')
          this.loadList()
        })
      }
    },
    async onRemoveMsg () {
      let checkedCount = 0
      let unreadCount = 0
      this.list.forEach(item => {
        if (item.checked) {
          checkedCount++

          if (item.msgStatus === 'N') {
            unreadCount++
          }
        }
      })

      if (unreadCount > 0) {
        this.onCheck('front.message.cannotDeleteMessage')
        return false
      }

      if (checkedCount <= 0) {
        this.onCheck('front.message.noSelectMessage')
        return false
      }
      const confirm = await this.onConfirm('front.message.confirmDeleteMessage')

      if (confirm) {
        for (let i = 0, iLen = this.list.length; i < iLen; i++) {
          const item = this.list[i]
          if (item.checked) {
            const params = {
              msgIdx: item.msgIdx,
              recieveId: item.recieveId
            }
            setMsgUpdate(params).then(response => {
            })
          }
        }
        await this.onCheck('front.message.completeDeleteMessage')
        this.loadList()
      }
    },
    async onAllRead () {
      const confirm = await this.onConfirm('front.message.confirmAllRead')

      if (confirm) {
        const params = {
        }
        setMsgRead(params).then(response => {
          const result = response.data
          if (result.resultCode === '0') {
            this.loadList()
          }
        })
      }
    }
  }
}
</script>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
<style scoped>
  .boardName {margin-bottom: 20px;}
  .pagenamew{margin-bottom: 0; gap: 20px;}
  .blc{color:#1a65f4 !important;}
  .boardbtn{display: flex; justify-content: space-between;margin-top: 20px;}
  .boardbtn > div {display: flex; gap: 10px;}
  .boardbtn a {width: 145px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    color: #fff;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.98);}
  .grsbtn {padding: 0;}
</style>
